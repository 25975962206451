<template>
  <div class="d-flex justify-center align-center">
    <div>
      <v-img
        src="../assets/logo-hippo.png"
        alt="Hippo Logo"
        width="190"
        class="d-block mx-auto mb-2"
        style="margin-top: 14vh"
      />
      <v-card class="pa-10" rounded="lg" height="514" width=" 415">
        <p class="text-title mb-9">Selamat Datang di CBT</p>
        <v-form ref="form">
          <HCTextFieldVue
            v-model="payloadLogin.email"
            title="Email"
            label="Email"
            :cutomRules="emailRules"
          ></HCTextFieldVue>
          <HCTextFieldVue
            v-model="payloadLogin.password"
            title="Password"
            label="Password"
            :type="showPassword ? 'password' : 'text'"
            :appendIcon="
              showPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
            "
            @clickAppend="() => (showPassword = !showPassword)"
          ></HCTextFieldVue>
        </v-form>
        <div class="d-flex justify-end mb-7 mt-5" style="width: 100%">
          <small class="text-small ml-auto"> Lupa kata sandi? </small>
        </div>
        <v-btn
          @click="login"
          rounded
          color="primary"
          width="100%"
          large
          dark
          :loading="loadingLogin"
        >
          Masuk
        </v-btn>
      </v-card>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapActions } from "vuex";
import HCTextFieldVue from "@/components/Base/HCTextField.vue";

export default {
  name: "Login",

  components: {
    HCTextFieldVue,
  },

  data: () => ({
    showPassword: true,
    loadingLogin: false,

    defaultPayloadLogin: {
      email: null,
      password: null,
    },
    payloadLogin: {
      email: null,
      password: null,
    },

    emailRules: [
      (v) => !!v || "E-mail wajib diisi",
      (v) => /.+@.+\..+/.test(v) || "E-mail harus valid",
    ],
  }),

  mounted() {
    this.setNavbar(false);
  },

  methods: {
    ...mapMutations("utilsModule", ["setNavbar"]),
    ...mapMutations("authModule", ["setLoginAuthData", "setLoginUserData"]),
    ...mapActions("authModule", ["postLogin"]),

    async login() {
      if (this.$refs.form.validate()) {
        this.loadingLogin = true;

        let resAuth = await this.postLogin(this.payloadLogin);
        if (resAuth.message) {
          this.setLoginAuthData(resAuth.access_token);
          this.setLoginUserData(resAuth.data);
          this.$router.push({ path: "/list-cbt" });
          location.reload()
        }
        this.loadingLogin = false;
      }
    },
  },
};
</script>
<style scoped>
.text-title {
  font-family: "Poppins";
  font-style: "normal";
  font-weight: 600;
  font-size: 20px;
  line-height: auto;
  color: #000000;
}

.text-small {
  font-family: "Poppins";
  font-style: "normal";
  font-weight: 400;
  font-size: 9px;
  line-height: auto;
  color: #1995ae;
  cursor: pointer;
}
</style>
