import { apiPost } from "@/utils/apiHandler";

const mutations = {};

const actions = {
  async postListExam({ }, payload) {
    return await apiPost("/exam", payload);
  },
  async postListDetailExam({ }, payload) {
    return await apiPost("/exam/detail", payload);
  },
  async postListHistoryExam({ }, payload) {
    return await apiPost("/exam/user/history", payload);
  },
  async postStartExam({ }, payload) {
    let res = await apiPost("/exam/user/start", payload);
    if (res.status == '200') {
      localStorage.setItem("access_token", res.token);
      let dateNow = new Date();
      localStorage.setItem("expire", dateNow.getTime() + 10 * 60 * 60 * 1000);
    }
    return res
  },
  async postReportParticipant({ commit }, payload) {
    return apiPost("/exam/report", payload)
  },
  async postAnswerParticipant({ }, payload) {
    return await apiPost("/exam/user/answer", payload);
  },
  async postFinishExam({ }, payload) {
    return await apiPost("/exam/user/finish", payload);
  },
  async reloadExam({}, payload) {
    return await apiPost("/question/load", payload);
  },
};

const getters = {};

export default {
  namespaced: true,
  getters,
  actions,
  mutations,
};
