import Vue from "vue";
import Vuex from "vuex";
import utilsModule from "./utils.module";
import authModule from "./auth.module";
import examModule from "./exam.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    utilsModule,
    authModule,
    examModule,
  },
});
