import { authInstance, examInstance } from "./api.js";
import Vue from "vue";

const errorApiHandler = (error) => {
  Vue.toasted.error(error.response.data.error, {
    duration: 3000,
    keepOnHover: true,
  });
};

const apiPostAuth = async (url, data) => {
  try {
    const response = await authInstance.post(url, data);
    if (response.data.error) {
      Vue.toasted.error(response.data.error, {
        duration: 3000,
        keepOnHover: true,
      });
    } else {
      Vue.toasted.success(response.data.message, {
        duration: 3000,
        keepOnHover: true,
      });
    }
    return response.data;
  } catch (error) {
    errorApiHandler(error);
    return error.response.data;
  }
};

const apiPost = async (url, data) => {
  try {
    const response = await examInstance.post(url, data);
    if (response.data.error) {
      Vue.toasted.error(response.data.error, {
        duration: 3000,
        keepOnHover: true,
      });
    } else {
      Vue.toasted.success(response.data.message, {
        duration: 3000,
        keepOnHover: true,
      });
    }
    return response.data;
  } catch (error) {
    errorApiHandler(error);
    return error.response.data;
  }
};

export { apiPostAuth, apiPost };
