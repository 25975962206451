import { apiPostAuth } from "@/utils/apiHandler";

const mutations = {
  setLoginAuthData(state, data) {
    localStorage.setItem("access_token", data);
  },
  setLoginUserData(state, data) {
    const user = {
      id: data.id,
      username: data.username,
      email: data.email,
      phone: data.phone,
      role_id: data.role_id,
    };
    localStorage.setItem("user_data", JSON.stringify(user));
    let dateNow = new Date();
    localStorage.setItem("expire", dateNow.getTime() + 10 * 60 * 60 * 1000);
  },
};

const actions = {
  async postLogin({}, payload) {
    return await apiPostAuth("/user/app/login", payload);
  },
};

const getters = {
  getSiswaData(state) {
    const userDataStorage = JSON.parse(localStorage.getItem("user_data"));
    return userDataStorage;
  },
};

export default {
  namespaced: true,
  getters,
  actions,
  mutations,
};
