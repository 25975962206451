<template>
  <div>
    <label v-if="label" class="font-400-12">{{ label }}</label>
    <v-text-field
      v-model="setValue"
      :placeholder="placeholder == '' ? title : placeholder"
      :disabled="disabled"
      :readonly="readonly"
      :loading="loading"
      class="my-input"
      :class="{ 'mt-2': label }"
      outlined
      :dense="dense"
      :hide-details="hideDetails"
      hide-spin-buttons
      :type="type"
      :min="min"
      :max="max"
      :rules="rulesText"
      :prepend-inner-icon="prependInnerIcon"
      :append-icon="appendIcon"
      @blur="isInputActive = false"
      @focus="isInputActive = true"
      @click:append="clickAppend"
    ></v-text-field>
  </div>
</template>
<script>
export default {
  name: "HCTextField",
  data() {
    return {
      rulesText: [(v) => !!v || v === 0 || `${this.title} tidak boleh kosong`],
      isInputActive: false,
    };
  },
  props: {
    cutomRules: {
      default() {
        return [];
      },
    },
    value: { default: null },
    label: { type: String, default: null },
    type: { type: String, default: "text" },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    dense: { type: Boolean, default: true },
    title: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    notRequired: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false },
    min: { type: String, default: "0" },
    max: { type: String, default: "" },
    appendIcon: { type: String, default: "" },
    prependInnerIcon: { type: String, default: "" },
  },
  computed: {
    setValue: {
      get() {
        if (this.type == "currency") {
          if (this.isInputActive) {
            return this.value == null ? "" : this.value.toString();
          } else {
            let data =
              this.value == null
                ? ""
                : "IDR " +
                  this.value
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.");
            return data;
          }
        }
        return this.value;
      },
      set(value) {
        if (this.type == "currency") {
          let newValue =
            value == null ? 0 : parseFloat(value.replace(/[^\d\.]/g, ""));
          value = newValue;
          if (isNaN(newValue)) {
            newValue = 0;
          }
        }
        this.$emit("input", value);
      },
    },
  },
  mounted() {
    if (this.notRequired) this.rulesText = [];
    if (this.type == "phone" || this.type == "number") {
      this.rulesText = [
        (v) => {
          if (v) {
            if (/\D/.test(v)) return `${this.title} harus berupa angka`;
            else return true;
          } else return `${this.title} tidak boleh kosong`;
        },
      ];
    }
    if (this.cutomRules.length > 0) this.rulesText = this.cutomRules;
  },
  methods: {
    clickAppend() {
      this.$emit("clickAppend");
    },
  },
};
</script>
<style>
.my-input.v-input .v-input__slot {
  border-radius: 10px;
  height: 48px !important;
}
.my-input.v-input .v-input__prepend-inner {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.my-input.v-input .v-input__append-inner {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

@media only screen and (max-width: 960px) {
  .my-input.v-input .v-input__slot {
    height: 10px !important;
  }
}
</style>
