const state = {
  isNavbar: true,
  isLoading: false,
};

const getters = {
  getLoading(state) {
    return state.isLoading;
  },
};

const mutations = {
  setNavbar(state, value) {
    state.isNavbar = value;
  },
  setLoading(state, value) {
    state.isLoading = value
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
