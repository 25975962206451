<template>
  <v-app id="test">
    <Loading v-model="isLoading" />

    <v-app-bar
      v-if="isNavbar"
      :height="isDesktop ? '88' : '59'"
      fixed
      class="px-6"
    >
      <div class="grey2--text">
        <div :class="{ 'font-700-14': !isDesktop, 'font-700-20': isDesktop }">
          Halo {{ user.username }}
        </div>
        <div :class="{ 'font-400-12': !isDesktop, 'font-400-14': isDesktop }">
          {{ date }}
        </div>
      </div>
      <v-spacer />
      <v-icon @click="logOut" class="pointer" large>mdi-exit-to-app</v-icon>
    </v-app-bar>

    <v-main>
      <v-container
        :class="{
          'big-top-margin': isNavbar && isDesktop,
          'small-top-margin': isNavbar && !isDesktop,
        }"
        fluid
      >
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import moment from "moment";
import Loading from "./components/Base/Loading.vue";
import { mapState } from "vuex";

export default {
  name: "App",
  components: {
    Loading,
  },

  data: () => ({
    user: {},
  }),

  computed: {
    ...mapState("utilsModule", ["isNavbar", "isLoading"]),
    date() {
      let newDate = Date.now();
      return moment(newDate).locale("id").format("dddd, D MMMM YYYY");
    },
    isDesktop() {
      return (
        this.$vuetify.breakpoint.name != "sm" &&
        this.$vuetify.breakpoint.name != "xs"
      );
    },
  },

  watch: {
    isNavbar(value) {
      this.user = Object.assign(
        {},
        JSON.parse(localStorage.getItem("user_data"))
      );
    },
  },

  mounted() {
    this.user = Object.assign(
      {},
      JSON.parse(localStorage.getItem("user_data"))
    );
  },

  methods: {
    logOut() {
      localStorage.removeItem("access_token");
      localStorage.removeItem("user_data");
      localStorage.removeItem("expire");
      localStorage.removeItem("cbt-question");
      localStorage.removeItem("cbt-detail");
      this.$router.push({ path: "/login" });
    },
  },
};
</script>
<style>
@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url("./assets/Poppins-Regular.ttf") format("truetype");
}

.big-top-margin {
  margin-top: 101px;
}
.small-top-margin {
  margin-top: 72px;
}

.pointer {
  cursor: pointer;
}

.small-rounded {
  border-radius: 10px !important;
}

.font-700-20 {
  font-weight: 700;
  font-size: 20px;
}
.font-700-18 {
  font-weight: 700;
  font-size: 18px;
}
.font-700-16 {
  font-weight: 700;
  font-size: 16px;
}
.font-700-14 {
  font-weight: 700;
  font-size: 14px;
}

.font-600-18 {
  font-weight: 600;
  font-size: 18px;
}
.font-600-16 {
  font-weight: 600;
  font-size: 16px;
}
.font-600-14 {
  font-weight: 600;
  font-size: 14px;
}
.font-600-12 {
  font-weight: 600;
  font-size: 12px;
}

.font-500-20 {
  font-weight: 500;
  font-size: 20px;
}
.font-500-16 {
  font-weight: 500;
  font-size: 16px;
}
.font-500-14 {
  font-weight: 500;
  font-size: 14px;
}
.font-500-12 {
  font-weight: 500;
  font-size: 12px;
}

.font-400-16 {
  font-weight: 400;
  font-size: 16px;
}
.font-400-14 {
  font-weight: 400;
  font-size: 14px;
}
.font-400-12 {
  font-weight: 400;
  font-size: 12px;
}
.font-400-10 {
  font-weight: 400;
  font-size: 10px;
}

.big-navbar {
  height: 88px !important;
}
.small-navbar {
  height: 59px;
}

.text-title-grey {
  font-family: "Poppins";
  font-style: "normal";
  font-weight: 700;
  font-size: 14px;
  line-height: auto;
  color: #737373;
}

.text-paragraph-14-grey {
  font-family: "Poppins";
  font-style: "normal";
  font-weight: 400;
  font-size: 12px;
  line-height: auto;
  color: #737373;
}

/* Scroll bar stylings */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--lightestgrey);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(177, 177, 177);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(148, 148, 148);
}
</style>
