<template>
  <div>
    <div
      class="green-bg green-bg-small-height"
      :class="{ 'green-bg-big-height': isDesktop }"
    ></div>

    <v-img
      :class="isDesktop ? 'ml-12 mt-14' : 'ml-4 mt-5'"
      src="../assets/landscape-logo.png"
      :height="isDesktop ? '44' : '20'"
      :width="isDesktop ? '237' : '106'"
    ></v-img>

    <v-row class="mx-5" :class="isDesktop ? 'mt-6' : 'mt-4'">
      <!-- Card Soal -->
      <v-col md="8" cols="12">
        <v-card rounded="xl">
          <v-card-title
            class="d-flex justify-space-between"
            :class="{ 'small-card-title': !isDesktop }"
          >
            <div>
              SOAL NO.
              <div
                class="big-box-title"
                :class="{ 'small-box-title': !isDesktop }"
              >
                {{ openedQuestion.number }}
              </div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2"
                    style="cursor: pointer"
                    color="warning"
                    @click="reloadQuestion(openedQuestion)"
                  >
                    mdi-reload-alert
                  </v-icon>
                </template>
                <span>Tekan jika gambar tidak muncul</span>
              </v-tooltip>
            </div>
            <div>
              SISA WAKTU
              <div
                class="big-box-title"
                :class="{ 'small-box-title': !isDesktop }"
              >
                <span v-if="String(hour).length == 1">0</span>{{ hour }}
              </div>
              :
              <div
                class="big-box-title"
                :class="{ 'small-box-title': !isDesktop }"
              >
                <span v-if="String(minutes).length == 1">0</span>{{ minutes }}
              </div>
              :
              <div
                class="big-box-title"
                :class="{ 'small-box-title': !isDesktop }"
              >
                <span v-if="String(second).length == 1">0</span>{{ second }}
              </div>
            </div>
          </v-card-title>
          <v-card-text>
            <v-divider />
            <v-card class="mt-6 pa-5" rounded="lg" outlined>
              <v-card-text class="pa-0">
                <div>
                  <span v-html="openedQuestion.question_text"></span>
                </div>
                <img
                  v-if="
                    openedQuestion.question_image != '' &&
                    openedQuestion.question_image != null &&
                    openedQuestion.question_image != 'null'
                  "
                  class="mx-auto mt-4"
                  :src="openedQuestion.question_image"
                  :style="isDesktop ? 'max-height: 250px' : 'max-width: 160px'"
                  alt=""
                />
                <v-divider class="my-4" />
                <div @click="chooseQuestion('a')" class="mb-2 pointer d-flex">
                  <div
                    class="mr-3 big-box-title"
                    :class="{
                      'small-box-title': !isDesktop,
                      'answered-box-title': openedQuestion.userAnswer == 'a',
                    }"
                  >
                    A
                  </div>
                  <div>
                    <div
                      v-if="
                        openedQuestion.a != '' &&
                        openedQuestion.a != null &&
                        openedQuestion.a != 'null'
                      "
                    >
                      <span
                        :class="
                          openedQuestion.userAnswer == 'a'
                            ? 'font-weight-black'
                            : ''
                        "
                        v-html="openedQuestion.a"
                      ></span>
                    </div>
                    <img
                      v-if="
                        openedQuestion.img_a != '' &&
                        openedQuestion.img_a != null
                      "
                      class="mx-auto ml-2"
                      :src="openedQuestion.img_a"
                      :style="
                        isDesktop ? 'max-height: 250px' : 'max-width: 120px'
                      "
                      alt=""
                    />
                  </div>
                </div>
                <div @click="chooseQuestion('b')" class="mb-2 pointer d-flex">
                  <div
                    class="mr-3 big-box-title"
                    :class="{
                      'small-box-title': !isDesktop,
                      'answered-box-title': openedQuestion.userAnswer == 'b',
                    }"
                  >
                    B
                  </div>
                  <div>
                    <div
                      v-if="
                        openedQuestion.b != '' &&
                        openedQuestion.b != null &&
                        openedQuestion.b != 'null'
                      "
                    >
                      <span
                        :class="
                          openedQuestion.userAnswer == 'b'
                            ? 'font-weight-black'
                            : ''
                        "
                        v-html="openedQuestion.b"
                      ></span>
                    </div>
                    <img
                      v-if="
                        openedQuestion.img_b != '' &&
                        openedQuestion.img_b != null
                      "
                      class="mx-auto ml-2"
                      :src="openedQuestion.img_b"
                      :style="
                        isDesktop ? 'max-height: 250px' : 'max-width: 120px'
                      "
                      alt=""
                    />
                  </div>
                </div>
                <div @click="chooseQuestion('c')" class="mb-2 pointer d-flex">
                  <div
                    class="mr-3 big-box-title"
                    :class="{
                      'small-box-title': !isDesktop,
                      'answered-box-title': openedQuestion.userAnswer == 'c',
                    }"
                  >
                    C
                  </div>
                  <div>
                    <div
                      v-if="
                        openedQuestion.c != '' &&
                        openedQuestion.c != null &&
                        openedQuestion.c != 'null'
                      "
                    >
                      <span
                        :class="
                          openedQuestion.userAnswer == 'c'
                            ? 'font-weight-black'
                            : ''
                        "
                        v-html="openedQuestion.c"
                      ></span>
                    </div>
                    <img
                      v-if="
                        openedQuestion.img_c != '' &&
                        openedQuestion.img_c != null
                      "
                      class="mx-auto ml-2"
                      :src="openedQuestion.img_c"
                      :style="
                        isDesktop ? 'max-height: 250px' : 'max-width: 120px'
                      "
                      alt=""
                    />
                  </div>
                </div>
                <div @click="chooseQuestion('d')" class="mb-2 pointer d-flex">
                  <div
                    class="mr-3 big-box-title"
                    :class="{
                      'small-box-title': !isDesktop,
                      'answered-box-title': openedQuestion.userAnswer == 'd',
                    }"
                  >
                    D
                  </div>
                  <div>
                    <div
                      v-if="
                        openedQuestion.d != '' &&
                        openedQuestion.d != null &&
                        openedQuestion.d != 'null'
                      "
                    >
                      <span
                        :class="
                          openedQuestion.userAnswer == 'd'
                            ? 'font-weight-black'
                            : ''
                        "
                        v-html="openedQuestion.d"
                      ></span>
                    </div>
                    <img
                      v-if="
                        openedQuestion.img_d != '' &&
                        openedQuestion.img_d != null
                      "
                      class="mx-auto ml-2"
                      :src="openedQuestion.img_d"
                      :style="
                        isDesktop ? 'max-height: 250px' : 'max-width: 120px'
                      "
                      alt=""
                    />
                  </div>
                </div>
                <div
                  @click="chooseQuestion('e')"
                  v-if="
                    (openedQuestion.e != '' &&
                      openedQuestion.e != null &&
                      openedQuestion.e != 'null') ||
                    (openedQuestion.img_e != '' && openedQuestion.img_e != null)
                  "
                  class="mb-2 pointer d-flex"
                >
                  <div
                    class="mr-3 big-box-title"
                    :class="{
                      'small-box-title': !isDesktop,
                      'answered-box-title': openedQuestion.userAnswer == 'e',
                    }"
                  >
                    E
                  </div>
                  <div>
                    <div
                      v-if="
                        openedQuestion.e != '' &&
                        openedQuestion.e != null &&
                        openedQuestion.e != 'null'
                      "
                    >
                      <span
                        :class="
                          openedQuestion.userAnswer == 'e'
                            ? 'font-weight-black'
                            : ''
                        "
                        v-html="openedQuestion.e"
                      ></span>
                    </div>
                    <img
                      v-if="
                        openedQuestion.img_e != '' &&
                        openedQuestion.img_e != null
                      "
                      class="mx-auto ml-2"
                      :src="openedQuestion.img_e"
                      :style="
                        isDesktop ? 'max-height: 250px' : 'max-width: 120px'
                      "
                      alt=""
                    />
                  </div>
                </div>
              </v-card-text>
            </v-card>

            <v-row class="mt-4">
              <v-col cols="4">
                <HCButtonVue
                  @click.native="nextPrevQuestion('prev')"
                  class="mb-2"
                  icon="mdi-less-than"
                  :name="isDesktop ? 'Soal Sebelumnya' : ''"
                  width="100%"
                  :height="isDesktop ? '40' : '32'"
                  :disabled="openedQuestion.number == 1"
                />
              </v-col>
              <v-col cols="4">
                <HCButtonVue
                  @click.native="markDoubth"
                  class="mb-2"
                  color="warning"
                  icon="mdi-help-box-outline"
                  :name="isDesktop ? 'Ragu-ragu' : ''"
                  width="100%"
                  :height="isDesktop ? '40' : '32'"
                />
              </v-col>
              <v-col cols="4">
                <HCButtonVue
                  @click.native="nextPrevQuestion('next')"
                  class="mb-2"
                  icon="mdi-greater-than"
                  appendIcon
                  :name="isDesktop ? 'Soal Selanjutnya' : ''"
                  width="100%"
                  :height="isDesktop ? '40' : '32'"
                  :disabled="openedQuestion.number == itemsQuestion.length"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Card Navigasi -->
      <v-col md="4" cols="12">
        <v-card class="xs-mt-4 mt-0" rounded="xl">
          <v-card-title
            class="d-flex justify-space-between"
            :class="{ 'small-card-title': !isDesktop }"
          >
            NOMOR SOAL
          </v-card-title>
          <v-card-text>
            <v-divider />
            <div
              class="mb-9 number-container number-container-lg"
              :class="{ 'number-container-sm': !isDesktop }"
            >
              <div
                v-for="(question, index) in itemsQuestion"
                :key="index"
                @click="changeQuestion(question)"
                class="big-number-box number-item my-4 pointer"
                :class="{
                  'small-number-box': !isDesktop,
                  doubth: question.status == 1,
                  answered: question.status == 2,
                  selected: question == openedQuestion,
                }"
              >
                {{ question.number }}
              </div>
            </div>
          </v-card-text>
          <div class="px-10">
            <div class="mb-1">
              <v-chip class="mr-3" label color="success"> &nbsp; &nbsp;</v-chip>
              <span>Sudah Dijawab</span>
            </div>
            <div class="mb-1">
              <v-chip class="mr-3" label color="warning"> &nbsp; &nbsp;</v-chip>
              <span>Ragu-ragu</span>
            </div>
            <div class="mb-1">
              <v-chip class="mr-3" label color="grey1"> &nbsp; &nbsp;</v-chip>
              <span>Belum Terjawab</span>
            </div>
            <HCButtonVue
              @click.native="showDialogDetail"
              class="my-8"
              name="Selesai"
              width="100%"
              :height="isDesktop ? '40' : '32'"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>

    <HCDialogVue v-model="selesaiDialog" formTitle="Peringatan">
      <div slot="body">
        <div class="cardInfoDetail">
          Setelah waktu ujian dihentikan, Anda tidak akan bisa lagi mengerjakan
          soal ini.
        </div>
        <div>
          <v-checkbox
            v-model="yakinSelesai"
            label="Saya yakin ingin menyelesaikan pengerjaan soal ini."
          ></v-checkbox>
        </div>
      </div>
      <HCButtonVue
        @click.native="closeDialogDetail"
        class="mr-2"
        slot="footer"
        name="Batal"
        :height="isDesktop ? '40' : '32'"
        color="grey2"
        outlined
      />
      <HCButtonVue
        @click.native="submitCBT"
        slot="footer"
        name="Selesai"
        :height="isDesktop ? '40' : '32'"
        icon="mdi-timer-outline"
        :disabled="!yakinSelesai"
      />
    </HCDialogVue>
  </div>
</template>
<script>
import { mapMutations, mapActions } from "vuex";
import HCButtonVue from "@/components/Base/HCButton.vue";
import HCDialogVue from "@/components/Base/HCDialog.vue";

export default {
  name: "Ujian-CBT",

  components: {
    HCButtonVue,
    HCDialogVue,
  },

  data: () => ({
    selesaiDialog: false,
    yakinSelesai: false,

    openedQuestion: {
      number: null,
      question_text: null,
      question_image: null,
      a: null,
      b: null,
      c: null,
      d: null,
      e: null,
      key: null,
      userAnswer: null,
      status: 0,
    },
    itemsQuestion: [],

    targetDate: null,
    // * Timer
    hour: 0,
    minutes: 0,
    second: 0,

    // * Deteksi Interaksi User
    tabFocus: false,
    timeoutId: 0,
    burnSecond: 0,
    countDown: null,

    defaultPayloadFinish: {
      exam_id: "",
      user_id: "",
    },
    payloadFinish: {
      exam_id: "",
      user_id: "",
    },

    trues: 0,
    falses: 0,
    totals: 0,
  }),

  computed: {
    isDesktop() {
      return (
        this.$vuetify.breakpoint.name != "sm" &&
        this.$vuetify.breakpoint.name != "xs"
      );
    },
  },

  watch: {
    // tabFocus(value) {
    //   if (value) {
    //     clearTimeout(this.timeoutId);
    //     this.burnSecond = 0;
    //   } else this.startBurnCountDown();
    // },
    // burnSecond(value) {
    //   console.log(value + " second");
    //   if (value > 15) {
    //     this.resetAnswer();
    //     alert(
    //       "Jawaban anda kami reset karena meninggalkan halaman terlalu lama"
    //     );
    //     clearTimeout(this.timeoutId);
    //     this.burnSecond = 0;
    //   }
    // },
  },

  created() {
    // this.detectFocusOut();
  },

  async mounted() {
    this.setNavbar(false);
    this.itemsQuestion = JSON.parse(localStorage.getItem("cbt-question"));
    if (!this.itemsQuestion) {
      this.$router.push("/list-cbt");
      return;
    }
    this.openedQuestion = this.itemsQuestion[0];

    let now = new Date();
    let arrayEnd = JSON.parse(
      localStorage.getItem("cbt-detail")
    ).end_duration.split(" ");
    this.targetDate = new Date(`${arrayEnd[0]}T${arrayEnd[1]}`);

    if (this.targetDate <= now) {
      await this.submitCBT();
      return;
    }

    this.countdownTimer();
    this.countDown = setInterval(this.countdownTimer, 1000);
  },

  methods: {
    ...mapMutations("utilsModule", ["setNavbar", "setLoading"]),
    ...mapActions("examModule", ["postFinishExam", "reloadExam"]),
    showDialogDetail() {
      this.selesaiDialog = true;
    },
    closeDialogDetail() {
      this.selesaiDialog = false;
      this.yakinSelesai = false;
    },

    // * CBT
    changeQuestion(questionData) {
      if (questionData != this.openedQuestion) {
        this.openedQuestion = questionData;
      }
    },
    editLocalStorage() {
      localStorage.setItem("cbt-question", JSON.stringify(this.itemsQuestion));
    },
    markDoubth() {
      let index = this.itemsQuestion.findIndex(
        (data) => data == this.openedQuestion
      );
      if (index != -1) {
        this.itemsQuestion[index].status = 1;
      }
      this.editLocalStorage();
    },
    nextPrevQuestion(type) {
      if (type == "next")
        this.openedQuestion = this.itemsQuestion[this.openedQuestion.number];
      else if (type == "prev")
        this.openedQuestion =
          this.itemsQuestion[this.openedQuestion.number - 2];
    },
    async chooseQuestion(answer) {
      let index = this.itemsQuestion.findIndex(
        (data) => data == this.openedQuestion
      );
      if (index != -1) {
        this.itemsQuestion[index].userAnswer = answer;
        this.itemsQuestion[index].status = 2;
      }
      this.editLocalStorage();
    },
    async checkAnswer(data) {
      this.trues = 0;
      this.falses = 0;
      this.totals = data.length;
      for (let i = 0; i < data.length; i++) {
        if (data[i].userAnswer == "") {
          continue;
        } else {
          await this.compareHash(`$.${data[i].userAnswer}.$`, data[i].key)
            .then((match) => {
              if (match) {
                this.trues++;
              } else {
                this.falses++;
              }
            })
            .catch((error) => {
              alert("Ada error saat menyimpan data");
            });
        }
      }

      return { trues: this.trues, falses: this.falses, totals: this.totals };
    },
    async submitCBT() {
      let question_answer = {};
      await this.setLoading(true);
      this.itemsQuestion.forEach(question => {
        question_answer[question.id] = question.userAnswer
      });

      const groupedByCategory = this.itemsQuestion.reduce((acc, question) => {
        const { category_id } = question;
        if (!acc[category_id]) {
          acc[category_id] = [];
        }
        acc[category_id].push(question);
        return acc;
      }, {});

      let dataPayload = {};
      for (const category_id in groupedByCategory) {
        let { trues, falses, totals } = await this.checkAnswer(
          groupedByCategory[category_id]
        );
        dataPayload[category_id] = {};
        dataPayload[category_id]["true"] = trues;
        dataPayload[category_id]["false"] = falses;
        dataPayload[category_id]["total"] = totals;
      }

      this.payloadFinish.exam_id = JSON.parse(
        localStorage.getItem("cbt-detail")
      ).id;
      this.payloadFinish.user_id = JSON.parse(
        localStorage.getItem("user_data")
      ).id;
      this.payloadFinish.question_answer = question_answer;
      this.payloadFinish.category = dataPayload;

      let resFinish = await this.postFinishExam(this.payloadFinish);

      this.payloadFinish = Object.assign({}, this.defaultPayloadFinish);

      if (resFinish.status == 200) {
        localStorage.removeItem("cbt-question");
        localStorage.removeItem("cbt-detail");
        this.$router.push({ path: "/list-cbt" });
      }
      await this.setLoading(false);
    },

    // * Timer Countdown
    countdownTimer() {
      const now = new Date();
      const timeDifference = this.targetDate - now;
      // console.log("timeDifference", timeDifference);
      // console.log("this.targetDate", this.targetDate);
      // console.log("now", now);

      if (timeDifference > 0) {
        this.hour = Math.floor(
          (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        this.minutes = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );
        this.second = Math.floor((timeDifference % (1000 * 60)) / 1000);
      } else {
        clearInterval(this.countDown);
        this.hour = 0;
        this.minutes = 0;
        this.second = 0;
        this.submitCBT();
      }
    },

    // * Hash 256
    async sha256(string) {
      const encoder = new TextEncoder();
      const data = encoder.encode(string);
      const hashBuffer = await crypto.subtle.digest("SHA-256", data);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray
        .map((byte) => byte.toString(16).padStart(2, "0"))
        .join("");
      return hashHex;
    },
    async compareHash(inputString, savedHash) {
      const hash = await this.sha256(inputString);
      return hash === savedHash;
    },

    // * Deteksi Interaksi User
    detectFocusOut() {
      let inView = false;

      const onWindowFocusChange = (e) => {
        if ({ focus: 1, pageshow: 1 }[e.type]) {
          if (inView) return;
          this.tabFocus = true;
          inView = true;
        } else if (inView) {
          this.tabFocus = !this.tabFocus;
          inView = false;
        }
      };

      window.addEventListener("focus", onWindowFocusChange);
      window.addEventListener("blur", onWindowFocusChange);
      window.addEventListener("pageshow", onWindowFocusChange);
      window.addEventListener("pagehide", onWindowFocusChange);
      let vueElement = this;
      window.addEventListener("fullscreenchange", function (event) {
        if (!document.fullscreenElement) {
          vueElement.countDown();
        }
      });
    },
    startBurnCountDown() {
      this.burnSecond++;
      this.timeoutId = setTimeout(this.startBurnCountDown, 1000);
    },
    toggleFullScreen() {
      const element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen(); // Firefox
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen(); // Safari
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen(); // IE/Edge
      }
    },
    resetAnswer() {
      this.itemsQuestion.forEach((question) => {
        question.userAnswer = "";
        question.status = 0;
      });
      localStorage.setItem("cbt-question", JSON.stringify(this.itemsQuestion));
    },
    async reloadQuestion(data) {
      await this.setLoading(true);
      let resNewQuestion = await this.reloadExam({ id: data.id });
      if (resNewQuestion.status == '200') {
        let newQuestion = resNewQuestion.data
        newQuestion.number = data.number;
        newQuestion.status = data.status;
        newQuestion.userAnswer = data.userAnswer;
        let currentQuestion = this.itemsQuestion.findIndex(
          (dataQuestion) => dataQuestion.number == data.number
        );
        if (currentQuestion != -1) {
          this.openedQuestion = newQuestion
          this.itemsQuestion[currentQuestion] = newQuestion;
          localStorage.setItem(
            "cbt-question",
            JSON.stringify(this.itemsQuestion)
          );
        }
      }
      await this.setLoading(false);
    },
  },
};
</script>
<style scoped>
/* Green BG */
.green-bg {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #1995ae;
  width: 100%;
}
.green-bg-small-height {
  height: 118px;
}

/* Card Title */
.green-bg-big-height {
  height: 219px;
}
.small-card-title {
  font-size: 12px !important;
}

/* Box Title */
.small-box-title {
  font-size: 10px !important;
  line-height: 23px !important;
  width: 23px !important;
  height: 23px !important;
}
.big-box-title {
  display: inline-block;
  font-size: 18px;
  text-align: center;
  background-color: #1995ae;
  border-radius: 6px;
  color: white;
  line-height: 32px;
  width: 32px;
  height: 32px;
}
.answered-box-title {
  background-color: #71d748 !important;
}

/* Number */
.number-container {
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
}
.number-container-lg {
  max-height: 492px;
}
.number-container-sm {
  max-height: 384px !important;
}
.number-item {
  flex-basis: 19%;
}
.big-number-box {
  margin-right: 1%;
  text-align: center;
  background-color: #c9c9c9;
  border-radius: 6px;
  color: white;
  line-height: 50px;
  height: 50px;
}
.small-number-box {
  line-height: 32px !important;
  height: 32px !important;
}
.selected {
  border: 2px solid black !important;
}
.doubth {
  background-color: #fbbc05 !important;
}
.answered {
  background-color: #71d748 !important;
}

/* Dialog */
.cardInfoDetail {
  font-size: 16px;
  border: 1px solid #fbbc05;
  border-radius: 13px;
  background-color: rgba(251, 188, 5, 0.15);
  padding: 10px;
}
</style>
