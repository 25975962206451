<template>
  <div class="px-6">
    <v-row>
      <v-col order="2" order-sm="1" cols="12" sm="6">
        <HCButtonVue
          v-if="page == 0"
          @click.native="changePage"
          name="Riwayat Ujian"
          :height="isDesktop ? '40' : '32'"
          icon="mdi-history"
        />
        <HCButtonVue
          v-else-if="page == 1"
          @click.native="changePage"
          name="Ruang Ujian"
          :height="isDesktop ? '40' : '32'"
          icon="mdi-history"
        />
        <HCButtonVue
          v-if="page == 2 || page == 3"
          @click.native="page == 2 ? (page = 1) : (page = 2)"
          icon="mdi-arrow-left"
          :height="isDesktop ? '40' : '32'"
          class="mr-2"
          :width="isDesktop ? '' : '100%'"
        />
      </v-col>
      <v-col order="1" order-sm="2" cols="12" sm="6">
        <HCTextFieldVue
          v-if="page == 0"
          title="Cari Ruang Ujian"
          placeholder="Cari Ruang Ujian"
          prependInnerIcon="mdi-magnify"
          hideDetails
          notRequired
          v-model="searchInput"
          @input="searchData"
        />
        <HCButtonVue
          v-else-if="page == 2"
          @click.native="showResultPeserta"
          name="Lihat Hasil"
          :height="isDesktop ? '40' : '32'"
          class="ml-auto d-block"
          :width="isDesktop ? '' : '100%'"
        />
      </v-col>
    </v-row>

    <v-data-table
      v-if="page != 2 && page != 3"
      class="mt-4 elevation-1"
      :headers="getHeaders"
      :items="getItems"
      :loading="fetchData"
      disable-sort
    >
      <v-progress-linear
        v-show="fetchData"
        slot="progress"
        indeterminate
      ></v-progress-linear>

      <!-- Slot Duration -->
      <template v-slot:[`item.duration`]="{ item }">
        {{ item.duration }} Menit
      </template>

      <!-- Slot Aksi -->
      <template v-slot:[`item.aksi`]="{ item }">
        <HCButtonVue
          @click.native="showDialogDetail(item)"
          class="my-2"
          name="Mulai Ujian"
          icon="mdi-timer-outline"
          :height="isDesktop ? '40' : '32'"
        />
      </template>

      <!-- Slot Detail -->
      <template v-slot:[`item.detail`]="{ item }">
        <HCButtonVue
          @click.native="showDetailPeserta(item)"
          class="my-2"
          name="Lihat"
          :height="isDesktop ? '40' : '32'"
        />
      </template>
    </v-data-table>

    <!-- Detail Peserta -->
    <div v-if="page == 2">
      <v-card class="mt-6">
        <v-card-title class="font-500-18">Detail</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col>
              <HCList
                title="Nama Ruang Ujian"
                :value="openedList.room"
                :cols="6"
                :vertical="!isDesktop"
              />
              <HCList
                title="Jenjang"
                :value="openedList.stage"
                :cols="6"
                :vertical="!isDesktop"
              />
              <HCList
                title="Durasi Ujian"
                :value="`${openedList.duration} menit`"
                :cols="6"
                :vertical="!isDesktop"
              />
              <HCList
                title="Waktu Pengerjaan Peserta"
                :value="dataReport.time"
                :cols="6"
                :vertical="!isDesktop"
              />
              <HCList
                title="Status"
                value="Sudah dikerjakan"
                :cols="6"
                :vertical="!isDesktop"
              />
            </v-col>
            <v-col>
              <HCList
                title="Jumlah Soal"
                :value="dataReport.total_question"
                :cols="6"
                :vertical="!isDesktop"
              />
              <HCList
                title="Benar"
                :value="dataReport.true"
                :cols="6"
                :vertical="!isDesktop"
              />
              <HCList
                title="Salah"
                :value="dataReport.false"
                :cols="6"
                :vertical="!isDesktop"
              />
              <HCList
                title="Tidak Dijawab"
                :value="dataReport.blank"
                :cols="6"
                :vertical="!isDesktop"
              />
              <HCList
                title="Nilai Hasil ujian"
                :value="dataReport.score"
                :cols="6"
                :vertical="!isDesktop"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mt-10">
        <v-card-title>Pencapaian</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row v-for="(data, index) in dataReport.report" :key="index">
            <v-col
              cols="12"
              md="2"
              class="pt-4 black--text"
              :class="{ 'text-right': isDesktop }"
            >
              <b>{{ data.category }}</b>
            </v-col>
            <v-col>
              <v-progress-linear
                color="primary"
                height="25"
                :value="data.score"
                rounded
              >
                <template v-slot:default="{ value }">
                  <strong>{{ Math.ceil(value) }}%</strong>
                </template>
              </v-progress-linear>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>

    <!-- Detail Peserta -->
    <v-row v-if="page == 3" class="" :class="isDesktop ? 'mt-6' : 'mt-4'">
      <v-col md="8" cols="12">
        <v-card rounded="xl">
          <v-card-title
            class="d-flex justify-space-between"
            :class="{ 'small-card-title': !isDesktop }"
          >
            Daftar Soal dan Jawaban
          </v-card-title>
          <v-card-text>
            <v-divider />
            <div class="font-400-14 black--text pa-10">
              <div
                v-for="(question, index) in dataAnswer"
                :key="question.id + index"
              >
                <div class="d-flex" :id="'question' + index">
                  <div class="mr-4">{{ index + 1 }}.</div>
                  <div>
                    <div>
                      <span
                        v-html="question.question_text"
                        class="d-block"
                      ></span>
                      <img
                        v-if="
                          question.question_image != '' &&
                          question.question_image != null &&
                          question.question_image != 'null'
                        "
                        class="mx-auto"
                        :src="question.question_image"
                        :style="
                          isDesktop ? 'max-height: 250px' : 'max-width: 160px'
                        "
                        alt=""
                      />
                    </div>
                    <div
                      class="d-flex"
                      :class="{
                        'font-weight-black primary--text': question.key == 'a',
                        'font-weight-black danger--text':
                          question.user_answer.toLowerCase() == 'a' &&
                          question.user_answer.toLowerCase() != question.key,
                      }"
                    >
                      <div>a.</div>
                      <div>
                        <div
                          v-if="
                            question.a != '' &&
                            question.a != null &&
                            question.a != 'null'
                          "
                        >
                          <span v-html="question.a"></span>
                        </div>
                        <img
                          v-if="question.img_a != '' && question.img_a != null"
                          class="mx-auto ml-2"
                          :src="question.img_a"
                          :style="
                            isDesktop ? 'max-height: 250px' : 'max-width: 120px'
                          "
                          alt=""
                        />
                      </div>
                    </div>
                    <div
                      class="d-flex"
                      :class="{
                        'font-weight-black primary--text': question.key == 'b',
                        'font-weight-black danger--text':
                          question.user_answer.toLowerCase() == 'b' &&
                          question.user_answer.toLowerCase() != question.key,
                      }"
                    >
                      <div>b.</div>
                      <div>
                        <div
                          v-if="
                            question.b != '' &&
                            question.b != null &&
                            question.b != 'null'
                          "
                        >
                          <span v-html="question.b"></span>
                        </div>
                        <img
                          v-if="question.img_b != '' && question.img_b != null"
                          class="mx-auto ml-2"
                          :src="question.img_b"
                          :style="
                            isDesktop ? 'max-height: 250px' : 'max-width: 120px'
                          "
                          alt=""
                        />
                      </div>
                    </div>
                    <div
                      class="d-flex"
                      :class="{
                        'font-weight-black primary--text': question.key == 'c',
                        'font-weight-black danger--text':
                          question.user_answer.toLowerCase() == 'c' &&
                          question.user_answer.toLowerCase() != question.key,
                      }"
                    >
                      <div>c.</div>
                      <div>
                        <div
                          v-if="
                            question.c != '' &&
                            question.c != null &&
                            question.c != 'null'
                          "
                        >
                          <span v-html="question.c"></span>
                        </div>
                        <img
                          v-if="question.img_c != '' && question.img_c != null"
                          class="mx-auto ml-2"
                          :src="question.img_c"
                          :style="
                            isDesktop ? 'max-height: 250px' : 'max-width: 120px'
                          "
                          alt=""
                        />
                      </div>
                    </div>
                    <div
                      class="d-flex"
                      :class="{
                        'font-weight-black primary--text': question.key == 'd',
                        'font-weight-black danger--text':
                          question.user_answer.toLowerCase() == 'd' &&
                          question.user_answer.toLowerCase() != question.key,
                      }"
                    >
                      <div>d.</div>
                      <div>
                        <div
                          v-if="
                            question.d != '' &&
                            question.d != null &&
                            question.d != 'null'
                          "
                        >
                          <span v-html="question.d"></span>
                        </div>
                        <img
                          v-if="question.img_d != '' && question.img_d != null"
                          class="mx-auto ml-2"
                          :src="question.img_d"
                          :style="
                            isDesktop ? 'max-height: 250px' : 'max-width: 120px'
                          "
                          alt=""
                        />
                      </div>
                    </div>
                    <div
                      v-if="
                        (question.e != '' &&
                          question.e != null &&
                          question.e != 'null') ||
                        (question.img_e != '' && question.img_e != null)
                      "
                      class="d-flex"
                      :class="{
                        'font-weight-black primary--text': question.key == 'e',
                        'font-weight-black danger--text':
                          question.user_answer.toLowerCase() == 'e' &&
                          question.user_answer.toLowerCase() != question.key,
                      }"
                    >
                      <div>e.</div>
                      <div>
                        <div
                          v-if="
                            question.e != '' &&
                            question.e != null &&
                            question.e != 'null'
                          "
                        >
                          <span v-html="question.e"></span>
                        </div>
                        <img
                          v-if="question.img_e != '' && question.img_e != null"
                          class="mx-auto ml-2"
                          :src="question.img_e"
                          :style="
                            isDesktop ? 'max-height: 250px' : 'max-width: 120px'
                          "
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <v-divider class="mb-8 mt-4" />
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="4" cols="12" style="position: relative">
        <v-card rounded="xl" class="card-question">
          <v-card-title
            class="d-flex justify-space-between"
            :class="{ 'small-card-title': !isDesktop }"
          >
            NOMOR SOAL
          </v-card-title>
          <v-card-text>
            <v-divider />
            <div
              class="mb-9 number-container number-container-lg"
              :class="{ 'number-container-sm': !isDesktop }"
            >
              <div
                v-for="(question, index) in dataAnswer"
                :key="index"
                @click="scrollToQuestion(index)"
                class="big-number-box number-item my-4 pointer"
                :class="{
                  'small-number-box': !isDesktop,
                  correct: question.key == question.user_answer.toLowerCase(),
                  wrong: question.key != question.user_answer.toLowerCase(),
                }"
              >
                {{ index + 1 }}
              </div>
            </div>
          </v-card-text>
          <div class="px-10 pb-8">
            <div class="mb-1">
              <v-chip class="mr-3" label color="primary"> &nbsp; &nbsp;</v-chip>
              <span>Jawaban Benar</span>
            </div>
            <div class="mb-1">
              <v-chip class="mr-3" label color="red"> &nbsp; &nbsp;</v-chip>
              <span>Jawaban Salah</span>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <!-- Detail Ujian -->
    <HCDialogVue v-model="detailDialog" formTitle="Detail Ujian">
      <v-form slot="body" ref="examCode">
        <div class="cardInfoDetail">
          {{ detailList.description }}
        </div>
        <v-divider class="my-4" />
        <HCTextFieldVue
          v-model="examCode"
          title="Masukkan Kode"
          label="Masukkan Kode Ruang Ujian"
          hideDetails
        />
      </v-form>
      <HCButtonVue
        @click.native="closeDialogDetail"
        class="mr-2"
        slot="footer"
        name="Batal"
        :height="isDesktop ? '40' : '32'"
        color="grey2"
        outlined
      />
      <HCButtonVue
        @click.native="startCBT"
        slot="footer"
        name="Kerjakan"
        icon="mdi-timer-outline"
        :height="isDesktop ? '40' : '32'"
        :disabled="!examCode"
      />
    </HCDialogVue>
  </div>
</template>
<script>
import { mapMutations, mapActions } from "vuex";
import HCButtonVue from "@/components/Base/HCButton.vue";
import HCTextFieldVue from "@/components/Base/HCTextField.vue";
import HCDialogVue from "@/components/Base/HCDialog.vue";
import HCList from "@/components/Base/HCList.vue";
import moment from "moment";

export default {
  name: "List-CBT",

  components: {
    HCButtonVue,
    HCTextFieldVue,
    HCDialogVue,
    HCList,
  },

  data: () => ({
    fetchData: false,
    searchInput: "",

    headers: [
      { text: "Ruang Ujian", value: "room" },
      { text: "Jenjang", value: "stage" },
      { text: "Waktu Ujian", value: "time_start" },
      { text: "Durasi Ujian", value: "duration" },
      { text: "Aksi", value: "aksi", align: "center" },
    ],
    headers2: [
      { text: "Ruang Ujian", value: "room" },
      { text: "Jenjang", value: "stage" },
      { text: "Durasi Ujian", value: "duration" },
      { text: "Waktu Mengerjakan", value: "time_start" },
      { text: "Soal", value: "total_question", align: "center" },
      { text: "Benar", value: "true", align: "center" },
      { text: "Salah", value: "false", align: "center" },
      { text: "Tidak dijawab", value: "blank", align: "center" },
      { text: "Nilai", value: "score", align: "center" },
      { text: "Detail", value: "detail", align: "center" },
    ],
    listCBT: [],
    listHistory: [],
    dataReport: {},
    dataAnswer: [],

    page: 0,
    openedList: null,
    detailList: {},
    examCode: null,

    detailDialog: false,
  }),

  computed: {
    isDesktop() {
      return (
        this.$vuetify.breakpoint.name != "sm" &&
        this.$vuetify.breakpoint.name != "xs"
      );
    },
    getHeaders() {
      if (this.page == 0) {
        return this.headers;
      } else if (this.page == 1) return this.headers2;
    },
    getItems() {
      if (this.page == 0) return this.listCBT;
      else if (this.page == 1) return this.listHistory;
    },
  },

  async mounted() {
    this.setNavbar(true);
    let localQuestion = localStorage.getItem("cbt-question");
    let localDetail = localStorage.getItem("cbt-detail");

    if (localQuestion != null && localDetail != null) {
      this.$router.push({ path: "/ujian-cbt" });
    } else {
      localStorage.removeItem("cbt-question");
      localStorage.removeItem("cbt-detail");
    }

    this.fetchData = true;
    await this.getListCBT();
    this.fetchData = false;
  },

  methods: {
    ...mapMutations("utilsModule", ["setNavbar", "setLoading"]),
    ...mapActions("examModule", [
      "postListExam",
      "postListDetailExam",
      "postListHistoryExam",
      "postStartExam",
      "postReportParticipant",
      "postAnswerParticipant",
    ]),

    scrollToQuestion(index) {
      const element = document.getElementById("question" + index);
      const yOffset = -100; // Offset negatif untuk memberi jarak dari atas elemen
      if (element) {
        const yPosition =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({
          top: yPosition,
          behavior: "smooth",
        });
      }
    },
    searchData() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        this.searchedSiswa = this.searchInput || "";
        this.fetchData = true;
        if (this.page == 0) {
          await this.getListCBT();
        } else {
          await this.getListHistory();
        }
        this.fetchData = false;
      }, 800);
    },
    async changePage() {
      this.searchInput = "";
      this.fetchData = true;
      if (this.page == 0) {
        await this.getListHistory();
        this.page = 1;
      } else {
        await this.getListCBT();
        this.page = 0;
      }
      this.fetchData = false;
    },

    async showDialogDetail(data) {
      this.openedList = data;
      try {
        let resDetail = await this.postListDetailExam({ id: data.id });
        if (resDetail.status == "200") {
          this.detailList = resDetail.data;
          this.detailDialog = true;
        }
      } catch (err) {
        alert("Error saat menampilkan detail");
      }
    },
    closeDialogDetail() {
      this.$refs.examCode.resetValidation();
      this.examCode = null;
      this.detailDialog = false;
    },

    async showDetailPeserta(data) {
      this.openedList = data;
      await this.setLoading(true);
      let resReport = await this.postReportParticipant({
        user_exam_id: data.id,
      });
      await this.setLoading(false);
      this.dataReport = resReport.data;
      this.page = 2;
    },
    async showResultPeserta() {
      await this.setLoading(true);
      let resAnswer = await this.postAnswerParticipant({
        user_exam_id: this.dataReport.user_exam_id,
        exam_id: this.dataReport.exam_id,
        packet_id: this.dataReport.packet_id,
      });
      await this.setLoading(false);
      this.dataAnswer = resAnswer.data;
      this.page = 3;
    },

    async getListCBT() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1 and pad with leading zero if needed
      const day = String(currentDate.getDate()).padStart(2, "0");

      const hour = String(currentDate.getHours()).padStart(2, "0");
      const minute = String(currentDate.getMinutes()).padStart(2, "0");
      const second = String(currentDate.getSeconds()).padStart(2, "0");

      let resListExam = await this.postListExam({
        room: this.searchInput,
        date: `${year}-${month}-${day} ${hour}:${minute}:${second}`,
        role_id: JSON.parse(localStorage.getItem("user_data")).role_id,
      });
      this.listCBT = resListExam.data.filter((data) => data.status == "active");
    },

    async getListHistory() {
      let resListHistory = await this.postListHistoryExam({
        room: this.searchInput,
        user_id: JSON.parse(localStorage.getItem("user_data")).id,
      });
      this.listHistory = resListHistory.data;
    },

    async startCBT() {
      let now = new Date();
      let start = new Date(this.detailList.date_start);
      let finish = new Date(this.detailList.date_finish);
      if (finish <= now) {
        alert("Ujian telah selesai");
        return;
      }
      if (start >= now) {
        alert("Ujian belum dimulai");
        return;
      }

      if (this.$refs.examCode.validate()) {
        await this.setLoading(true);
        let question = [];
        try {
          question = await this.postStartExam({
            exam_id: this.detailList.id,
            code: this.examCode.replace(/ /g, ""),
            user_id: JSON.parse(localStorage.getItem("user_data")).id,
          });
        } catch (err) {
          alert("Error saat start CBT");
          await this.setLoading(false);
          return;
        }

        if (question.status == 400) {
          await this.setLoading(false);
          return;
        }

        // * Cek apakah sudah pernah mengerjakan exam
        let localQuestion = localStorage.getItem("cbt-question");
        let localDetail = localStorage.getItem("cbt-detail");

        const { id: localDetailId } = localDetail
          ? JSON.parse(localDetail)
          : {};

        if (
          localQuestion == null ||
          localDetail == null ||
          localDetailId != this.openedList.id
        ) {
          if (question.data.length > 0) {
            this.setLocalData(question);
            this.$router.push({ path: "/ujian-cbt" });
          }
        }
        await this.setLoading(false);
      }
    },
    setLocalData(question) {
      question.data.forEach((data, index) => {
        data.number = index + 1;
        data.userAnswer = "";
        data.status = 0;
      });
      this.examCode = "";
      localStorage.setItem("cbt-question", JSON.stringify(question.data));

      this.detailList.end_cbt = this.detailList.date_finish;
      // * Set end duration
      let currentDate = new Date();
      // console.log(currentDate);
      currentDate.setTime(
        currentDate.getTime() + parseInt(this.openedList.duration) * 60 * 1000
      );
      // console.log(currentDate, new Date(this.detailList.end_cbt));
      // console.log(currentDate > new Date(this.detailList.end_cbt));
      if (currentDate > new Date(this.detailList.end_cbt)) {
        this.detailList.end_duration = this.detailList.end_cbt;
      } else {
        this.detailList.end_duration = moment(currentDate).format(
          "YYYY-MM-DD HH:mm:ss"
        );
      }
      localStorage.setItem("cbt-detail", JSON.stringify(this.detailList));
    },
  },
};
</script>
<style scoped>
.cardInfoDetail {
  font-size: 16px;
  border: 1px solid #1995ae;
  border-radius: 13px;
  background-color: rgba(10, 68, 68, 0.15);
  padding: 10px;
}
/* Number */
.card-question {
  position: sticky;
  top: 88px;
  right: 0;
  left: 12px;
}
.number-container {
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
}
.number-container-lg {
  max-height: 492px;
}
.number-container-sm {
  max-height: 384px !important;
}
.number-item {
  flex-basis: 19%;
}
.big-number-box {
  margin-right: 1%;
  text-align: center;
  background-color: #c9c9c9;
  border-radius: 6px;
  color: white;
  line-height: 50px;
  height: 50px;
}
.small-number-box {
  line-height: 32px !important;
  height: 32px !important;
}
.correct {
  background-color: #1995ae !important;
}
.wrong {
  background-color: #dc2626 !important;
}
</style>
