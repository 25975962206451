<template>
  <div v-if="vertical">
    <div class="font-600-12">{{ title }}</div>
    <div class="font-400-12">{{ value }}</div>
  </div>
  <v-row v-else class="mb-3">
    <v-col v-if="$slots.customTitle" class="pb-0" :cols="cols">
      <slot name="customTitle" />
    </v-col>
    <v-col v-else class="pb-0 font-400-14 black--text" :cols="cols">
      {{ title }}
    </v-col>
    <v-col v-if="$slots.customValue" class="pb-0">
      <slot name="customValue" />
    </v-col>
    <v-col v-else class="pb-0 font-400-14 off--text">{{ value }}</v-col>
  </v-row>
</template>

<script>
export default {
  name: "HCList",
  props: {
    title: { type: String, default: "Title" },
    value: { default: "Value" },
    maxWidth: { type: String, default: "500" },
    vertical: { type: Boolean, default: false },
    cols: { type: Number, default: 2 },
  },
};
</script>
