<template>
  <v-btn
    :class="addClass"
    class="rounded-lg"
    :color="color"
    :outlined="outlined"
    :height="height"
    :width="width"
    :loading="loading"
    :dark="dark"
    :elevation="elevation"
    :small="small"
    :x-small="xSmall"
    :large="large"
    :x-large="xLarge"
    :disabled="disabled"
  >
    <span :class="colorText">
      <v-icon
        v-if="icon != null && !appendIcon"
        :small="smallIcon"
        :large="largeIcon"
        class="mr-1"
      >
        {{ icon }}
      </v-icon>
      {{ name }}
      <v-icon
        v-if="icon != null && appendIcon"
        :small="smallIcon"
        :large="largeIcon"
        class="mr-1"
      >
        {{ icon }}
      </v-icon>
    </span>
  </v-btn>
</template>
<script>
export default {
  name: "HCButton",
  props: {
    name: { default: "" },
    icon: { default: null },
    colorText: { default: null },
    smallIcon: { type: Boolean, default: false },
    largeIcon: { type: Boolean, default: false },
    addClass: { default: "" },
    color: { type: String, default: "primary" },
    outlined: { type: Boolean, default: false },
    height: { type: String, default: "56" },
    width: { type: String, default: null },
    loading: { type: Boolean, default: false },
    dark: { type: Boolean, default: false },
    elevation: { default: 1 },
    small: { type: String, default: null },
    xSmall: { type: String, default: null },
    large: { type: String, default: null },
    xLarge: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    appendIcon: { type: Boolean, default: false },
  },
};
</script>
<style lang="scss" scoped>
.v-btn {
  text-transform: unset !important;
}
</style>
