<template>
  <v-dialog
    v-model="show"
    :max-width="maxWidth"
    scrollable
    persistent
    overlay-opacity="0.95"
    :fullscreen="fullscreen"
  >
    <v-card rounded="xl">
      <v-card-title class="py-3 px-5">
        <span class="font-weight-medium">{{ formTitle }}</span>
      </v-card-title>

      <v-divider class="lighten-3" />

      <slot name="bodyCustom" />

      <v-card-text v-if="this.$slots.body">
        <div class="pt-5">
          <slot name="body" />
        </div>
      </v-card-text>

      <div v-if="this.$slots.footer">
        <v-divider />
        <v-card-actions class="pa-4">
          <v-row justify="end" class="pa-2">
            <slot name="footer" />
          </v-row>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "HCDialog",
  props: {
    value: Boolean,
    formTitle: { type: String, default: "dialog" },
    maxWidth: { type: String, default: "500" },
    fullscreen: { type: Boolean, default: false },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
