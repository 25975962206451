import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "../views/Login.vue";
import ListCBTView from "../views/ListCBT.vue";
import UjianCBTView from "../views/UjianCBT.vue";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/list-cbt",
    name: "list-cbt",
    component: ListCBTView,
    meta: { requiresAuth: true },
  },
  {
    path: "/ujian-cbt",
    name: "ujian-cbt",
    component: UjianCBTView,
    meta: { requiresAuth: true },
  },
  {
    path: "/:notFound",
    redirect: "/login",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const expire = localStorage.getItem("expire");
  const now = new Date();
  if (to.meta.requiresAuth) {
    if (now.getTime() > expire) {
      localStorage.removeItem("user_data");
      localStorage.removeItem("access_token");
      localStorage.removeItem("expire");
      localStorage.removeItem("cbt-question");
      localStorage.removeItem("cbt-detail");
      next("/login");
    } else {
      next();
    }
  } else if (to.name === "login") {
    if (now.getTime() > expire) {
      localStorage.removeItem("user_data");
      localStorage.removeItem("access_token");
      localStorage.removeItem("expire");
      localStorage.removeItem("cbt-question");
      localStorage.removeItem("cbt-detail");
      next();
    } else {
      next("/list-cbt");
    }
  } else {
    next();
  }
});

export default router;
